<template>
  <v-card>
    <v-system-bar
    v-if="!completed && surveyLanguages && surveyLanguages.length > 1"
      dark
      height="50"
      color="primary"
    >
      <v-spacer></v-spacer>
      <v-col class="mb-4" lg="2" xl="2" md="4" sm="5" xs="6">
        <v-select v-model="selectedLanguage"
          :items="surveyLanguages"
          item-text="name"
          item-value="code"
          label="Language"
          hide-details
          prepend-icon="mdi-earth"
          single-line>
      </v-select>
      </v-col>    
    </v-system-bar>
    <div v-if="!startQuestions && !completed">
      <v-card-title>{{ survey.name[selectedLanguage] }}</v-card-title>
      <v-card-subtitle v-if="survey.description[selectedLanguage]">{{
        survey.description[selectedLanguage]
      }}</v-card-subtitle>
      <v-divider class="mx-5"></v-divider>
      <div class="d-flex flex-row-reverse mt-5 pr-5">
        <v-btn @click="startQuestions = true" class="mb-5" color="primary">
          {{$t("next", selectedLanguage)}}
        </v-btn>
      </div>
    </div>
    <v-form
      v-else-if="startQuestions"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-container>
        <div v-for="(question,questionIndex) in survey.pages[pageIndex].questions" :key="questionIndex">
        <div class="my-2">
          <div class="subtitle-1">
            {{ question.title[selectedLanguage]}}
          </div>
          <div
            v-if="question.note[selectedLanguage]"
            class="caption"
          >
            {{ question.note[selectedLanguage] }}
          </div>
        </div>

        <v-text-field
          v-if="question.questionType == 'text'"
          label="Answer Here"
          outlined
          v-model="question.answer"
        ></v-text-field>

        <v-radio-group
          v-if="question.questionType == 'radio'"
          v-model="question.answer"
          :key="question._id"
        >
          <v-radio
            v-for="option in question.options"
            :key="option[selectedLanguage]"
            :label="option[selectedLanguage]"
            :value="option[selectedLanguage]"
          ></v-radio>
        </v-radio-group>

        <v-select
          v-if="question.questionType == 'checkbox'"
          chips
          multiple
          v-model="question.answer"
          :items="question.options"
          :item-text="selectedLanguage"
          :item-value="selectedLanguage"
          label="Answer Here"
          outlined
        >
        </v-select>

        <table v-if="question.questionType == 'radioGrid'">
          <thead>
            <tr>
              <th></th>
              <th v-for="column in question.optionsColumns" :key="column">
                
                  {{ column[selectedLanguage] }}
                
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in question.optionsRows" :key="index">
              <td>{{ row[selectedLanguage] }}</td>
              <td style="text-align: center;" v-for="(column, columnIndex) in question.optionsColumns" :key="columnIndex">
                <input type="radio" :name="`question-${index}`" :value="column[selectedLanguage]" v-model="question.answer[index].value">
              </td>
            </tr>
          </tbody>
        </table>
        <v-file-input v-if="question.questionType == 'file'"
          label="File input"
          accept="image/*,video/*"
          @change="assignFileUrlToAnswer(question, $event)"
          :ref="question._id"
          prepend-icon="mdi-camera"
        ></v-file-input>
      </div>
      <div class="d-flex flex-row-reverse mt-5 pr-5">
          <v-btn @click="nextPage" class="mb-5" color="primary">{{$t("next", selectedLanguage)}}</v-btn>
      </div>
    </v-container>
    </v-form>
    <div v-if="completed">
      <v-card-title>{{ $t("completed", selectedLanguage) }}!</v-card-title>
      <v-card-subtitle>{{ $t("survey_submitted", selectedLanguage) }}</v-card-subtitle>
    </div>

    <v-snackbar
      :timeout="2000"
      v-model="questionRequiredSnackbar"
      color="blue-grey"
      rounded="pill"
      vertical
    >
      Question Is Required
    </v-snackbar>
  </v-card>
</template>
<script>
import { SurveyService } from "../../services/SurveyService";
const surveyService = new SurveyService();
export default {
  data() {
    return {
      survey:{},
      allLanguagesItems:[],
      pageIndex: 0,
      startQuestions: false,
      completed: false,
      questionRequiredSnackbar: false,
      valid: true,
      selectedLanguage: "en",
    };
  },

  async mounted() {
    await this.getSurvey();
    await this.getAllLanguages();
    // if theres a lang param in the url, and its one of the surveyLanguages codes, set it as the selectedLanguage
    if(this.$route.query.lang && this.surveyLanguages.map(lang => lang.code).includes(this.$route.query.lang))
        this.selectedLanguage = this.$route.query.lang;
  },

  methods: {
    async getSurvey(){
      await surveyService.getSurvey(this.$route.params.id).then((response) => {
        this.survey = response;
        this.survey.pages.forEach((page) => {
        page.questions.forEach((question) => {
        if (question.questionType == "radioGrid") {
            question.answer = new Array(question.optionsRows.length);
            question.optionsRows.forEach((row, index) => {
                question.answer[index] = { option: row, value: null }
            });
         }
        });
      });
    });
    },
    async nextPage() {
      this.questionRequiredSnackbar = false;
      for(const question of this.survey.pages[this.pageIndex].questions){
        if (question.required && (!question.answer || question.answer.length === 0)
        ||(question.questionType == "radioGrid" && question.answer.some((answer) => answer.value == null)))
        {
          this.questionRequiredSnackbar = true;
          return;
        }

      //piping logic

      // piping terminate logic
      if(question.piping && question.piping.terminate && question.answer == question.piping.value){
          this.submitQuestions();
          return;
      }

      //piping skip question logic
      if (question.piping && question.piping.condition && question.piping.questionToSkip) {
        let questionToSkip = null;
        // check each condition
        switch (question.piping.condition) {
          case "equals":
            if (question.answer == question.piping.value)
              questionToSkip = question.piping.questionToSkip;
            break;

          case "notEquals":
            if (question.answer != question.piping.value)
              questionToSkip = question.piping.questionToSkip;
            break;

          case "greaterThan":
            if (question.answer > question.piping.value)
              questionToSkip = question.piping.questionToSkip;
            break;

          case "lessThan":
            if (question.answer < question.piping.value)
              questionToSkip = question.piping.questionToSkip;
            break;

          case "greaterThanOrEquals":
            if (question.answer >= question.piping.value)
              questionToSkip = question.piping.questionToSkip;
            break;

          case "lessThanOrEquals":
            if (question.answer <= question.piping.value)
              questionToSkip = question.piping.questionToSkip;
            break;
          // contains and notContains are for radio answers
          case "contains":
            if (
              question.piping.value.includes(
                question.answer
              )
            )
              questionToSkip = question.piping.questionToSkip;
            break;

          case "notContains":
            if (
              !question.piping.value.includes(
                question.answer
              )
            )
              questionToSkip = question.piping.questionToSkip;
            break;

          // containsAny, containsAll, notContainAny, notContainAll are for checkbox answers
          case "containsAny":
            if (
              this.survey.questions[
                this.pageIndex
              ].piping.answer.some((option) =>
                question.answer.includes(
                  option
                )
              )
            )
              questionToSkip = question.piping.questionToSkip;
            break;

          case "containsAll":
            if (
              this.survey.questions[
                this.pageIndex
              ].piping.answer.every((option) =>
                question.answer.includes(
                  option
                )
              )
            )
              questionToSkip = question.piping.questionToSkip;
            break;

          case "notContainAny":
            if (
              !this.survey.questions[
                this.pageIndex
              ].piping.answer.some((option) =>
                question.answer.includes(
                  option
                )
              )
            )
              questionToSkip = question.piping.questionToSkip;
            break;

          case "notContainAll":
            if (
              !this.survey.questions[
                this.pageIndex
              ].piping.answer.every((option) =>
                question.answer.includes(
                  option
                )
              )
            )
              questionToSkip = question.piping.questionToSkip;
            break;

          default:
            break;
        }
  
            // if questionToSkip is not null, find the index of the question to skip
        if (questionToSkip) {     
          // find index of question in all pages
          let questionToSkipIndex = null;
          for (let i = 0; i < this.survey.pages.length; i++) {
            for (let j = 0; j < this.survey.pages[i].questions.length; j++) {
              if (this.survey.pages[i].questions[j]._id == questionToSkip) {
                questionToSkipIndex = j;
                this.survey.pages[i].questions.splice(questionToSkipIndex, 1);
                // if survey has no questions left, remove the page
                if (this.survey.pages[i].questions.length == 0) {
                  this.survey.pages.splice(i, 1);
                  // if index is the last page, complete the survey
                  if (i == this.survey.pages.length) {
                    this.submitQuestions();
                    this.completed = true;
                    this.startQuestions = false;
                    return;
                  }
                }
                break;
              }
            }
          }
        }
      }
    }

        // if it's the last page, submit
        if (this.pageIndex == this.survey.pages.length - 1) {
          this.submitQuestions();
          this.completed = true;
          this.startQuestions = false;
          return;
        }
        if(this.survey.pages[this.pageIndex].webHook && this.survey.pages[this.pageIndex].webHook.url){
          // loop through all the route query params and add them to the service.callWebHook function
          let queryObject = {}
          for (let key in this.$route.query) 
            queryObject[key] = this.$route.query[key]
          await surveyService.callWebHook(this.survey.pages[this.pageIndex].webHook, {query:queryObject, data:this.response})
        }
          
        this.pageIndex++;
    },

    async submitQuestions() {
      this.$setLoader();
      let data = {
        surveyId: this.$route.params.id,
        response: this.response,
        selectedLanguage: this.selectedLanguage,
      };
      if(this.$store.state.loggedIn && this.$store.state.token){
        await surveyService.submitUserSurvey(data, this.$store.state.token);
        // user could be submitting 2 mobile types: taskSurvey and userProfile
        // brid is the user id, maid is the useractivity id, mpid is the project id
        if(this.$route.query.brid && this.$route.query.maid && this.$route.query.mpid)
          await surveyService.submitTaskSurvey({userActivityId:this.$route.query.maid, taskData:this.response}, this.$store.state.token);
        else if(this.$route.query.userProfilingId)
          await surveyService.submitUserProfileSurvey({userProfilingId:this.$route.query.userProfilingId,response:this.response}, this.$store.state.token);
      }
      else
        await surveyService.submitGuestSurvey(data);
      this.$clearLoader();
    },
    async getAllLanguages() {
      this.allLanguagesItems = [];
      await surveyService.fetchSelectedSurveysLanguages().then(r => {
        this.allLanguagesItems = r
        if(!this.allLanguagesItems.find(item => item.code == "en"))
          this.allLanguagesItems.push({code:"en", name:"English"})
      });
    },
    assignFileUrlToAnswer(question, file) {
      if(!file || !(file instanceof File))
        return;

      let type = file.type;
      let fileType = type.split("/");
      this.$setLoader();
      file.resource_type = fileType[0];
      if (file.resource_type == "image") 
        file.url = process.env.VUE_APP_CLOUDINARY_URL_IMAGE;
      else if (file.resource_type == "video") 
        file.url = process.env.VUE_APP_CLOUDINARY_URL_VIDEO;
      else{
        this.$clearLoader();
        alert("INVALID FILE UPLOADED !");
        file = null;
        return;
      }          
      this.uploadFileToCloudinary(file).then((fileResponse) => {
        question.answer = fileResponse.url;
      });
      this.$clearLoader();
    },

    uploadFileToCloudinary(file) {
      return new Promise(function(resolve, reject) {
        let formData = new FormData();
        formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_PRESET2
        );
        formData.append("resource_type", file.resource_type);
        formData.append("file", file);
        let request = new XMLHttpRequest();
        request.open("POST", file.url, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },
  },
computed:{
  surveyLanguages(){
    if(!this.survey.languages || this.allLanguagesItems.length == 0)
      return []
    
  //return only the langauges that are in the survey.languages
   return this.allLanguagesItems.filter(item => this.survey.languages.includes(item.code))
  },
  response(){
    let response = [];
    this.survey.pages.forEach(page => {
      page.questions.forEach(question => {
        response.push({
          _id: question._id,
          title: question.title["en"],
          questionType: question.questionType,
          answer: question.answer,
        });
      });
    });
    return response;
  }
}
};
</script>
  
<style scoped>
::v-deep .v-snack__wrapper {
  min-width: 180px;
}

table {
    table-layout: fixed;
    width: 100%;
    overflow-x: scroll;
  overflow-y: scroll;
}

@media (max-width: 600px) {
  th, td {
    /* styles for your cells when viewed on a mobile device */
    font-size: 10px;
    padding: 4px;
  }
}

</style>
