import axios from 'axios'
let Util = require('./Util')
var API_URL = process.env.VUE_APP_BACKEND_URL

export class SurveyService {
  async getSurvey(id){
    const url = `${API_URL}/survey`
    const response = await axios.post(url, { surveyId: id });
    return response.data;
  }

  async submitGuestSurvey(data){
    const url = `${API_URL}/survey/submitGuest`
    const response = await axios.post(url, data);
    return response.data;
  }

  async submitUserSurvey(data, token){
    const url = `${API_URL}/survey/submitUser`
    const response = await axios.post(url, data, Util.appendMobileUserHeaders(token));
    return response.data;
  }

  async fetchSelectedSurveysLanguages(){
    const url = `${API_URL}/survey/languages`
    const response = await axios.post(url, {});
    return response.data;
  }

  async callWebhook(url, data){
    await axios.post(url, data);
  }

  async submitTaskSurvey(data, token){
    const url = `${API_URL}/user/survey/update`
    const response = await axios.post(url, data, Util.appendMobileUserHeaders(token));
    return response.data;
  }

  async submitUserProfileSurvey(data, token){
    const url = `${API_URL}/user/profiling/submit`
    const response = await axios.post(url, data, Util.appendMobileUserHeaders(token));
    return response.data;
  }
}